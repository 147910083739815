import summarizeStyle from "./summarize.module.css";
import React, { useState } from "react";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import SearchIcon from "@mui/icons-material/Search";
import CircularLoading from "../../components/loadModules/circularLoading.js";
import { toast } from "react-toastify";
import ReactiveTable from "../../components/tables/ReactiveTable.js";
import ActionBtn from "../../components/UI/actionbtn";
import api from "../../services/api.js";

export default function SummarizeReactive() {
  const [initDate, setInitDate] = useState("");
  const [endDate, setEndDte] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  function handleEndDate(value) {
    setEndDte(value);
  }

  function handleInitDate(value) {
    setInitDate(value);
  }
  const dateConversion = (dateToConvert) => {
    return new Date(new Date(dateToConvert).getTime()).toISOString();
  };

  function cleanSearch() {}

  async function loadTables() {
    if (initDate === "" || endDate === "") {
      toast.warn("Favor inserir datas de pesquisa");
      return;
    } else {
      setLoading(true);

      await api
        .get(
          `api/database/report/reativo?date_start=${dateConversion(
            initDate
          )}&date_end=${dateConversion(endDate)}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          if (res.data.length === 0) {
            toast.error("Não há relatórios para esta data");
          } else {
            toast.success("Busca realizada com sucesso");
            setData(res.data);
          }
        })
        .catch((e) => {
          toast.error("Erro #003: Falha na pesquisa");
          console.log("Ocorreu um erro: ", e);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }
  return (
    <div className={summarizeStyle.summarize}>
      <div className="d-flex align-items-center justify-content-between pe-4 mt-2 mb-1">
        <div className="titlePage">Relatório: POS - Orquestrador</div>
        <div className="d-flex align-items-center">
          <div
            className={`${summarizeStyle.containerTimerHeader} d-flex align-items-center me-4`}
          >
            <label className="defaultLabel me-2">De: </label>
            <input
              className="input-bootstrap"
              type="dateTime-Local"
              id="searchdatefrom"
              onChange={(e) => handleInitDate(e.target.value)}
            />
          </div>
          <div
            className={`${summarizeStyle.containerTimerHeader} d-flex align-items-center me-4`}
          >
            <label className="defaultLabel me-2">Até: </label>
            <input
              className="input-bootstrap"
              type="dateTime-Local"
              id="searchdateto"
              onChange={(e) => handleEndDate(e.target.value)}
            />
          </div>

          <div
            onClick={() => {
              cleanSearch();
            }}
          >
            <ActionBtn color="yellow" icon={<SearchOffIcon />} />
          </div>

          <div
            onClick={() => {
              loadTables();
            }}
          >
            <ActionBtn color="green" icon={<SearchIcon />} />
          </div>
        </div>
      </div>
      <div className={summarizeStyle.sumBottomBox}>
        {loading ? (
          <CircularLoading></CircularLoading>
        ) : (
          <ReactiveTable data={data} />
        )}
      </div>
    </div>
  );
}
