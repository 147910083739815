import { toast } from "react-toastify";
import React, { useState, useEffect } from "react";
import "../genesys.css";
import api from "../../../services/api";
import { PURECLOUD_URL } from "../../../services/utils";

export default function AddTab() {
  const [tabs, setTabs] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    loadTabs();
  }, []);

  async function loadTabs() {
    try {
      const res = await api.get(
        `/api/espresso/tabulacao/findqueue/SuporteChat`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setTabs(res.data.child || []);
    } catch (err) {
      toast.error(err.message || "Erro ao carregar tabs");
    }
  }

  function renderDropdowns(data, level = 0) {
    if (!data || data.length === 0) return null;

    return (
      <div key={level}>
        <select
          className="tabSelect"
          onChange={(event) => handleSelect(event.target.value, level, data)}
        >
          <option value="">Selecionar Nível {level + 1}</option>
          {data.map((item) => (
            <option key={item.key} value={item.key}>
              {item.descricao}
            </option>
          ))}
        </select>
        {selectedOptions[level] &&
          renderDropdowns(
            data.find((item) => item.key === selectedOptions[level])?.child ||
              [],
            level + 1
          )}
      </div>
    );
  }

  function handleSelect(value, level, data) {
    const newSelectedOptions = selectedOptions.slice(0, level);
    newSelectedOptions[level] = value;
    setSelectedOptions(newSelectedOptions);
  }

  async function handleSubmit(event) {
    event.preventDefault();
    const wrap = selectedOptions.join("-");
    const body = {
      wrap,
      number: window.location.pathname.split("/")[3],
      origin: window.location.pathname.split("/")[4],
      interactionId: window.location.pathname.split("/")[5],
      baseUrl: PURECLOUD_URL,
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("pcToken")}`,
      },
    };

    try {
      await api.post(
        `/api/inbound/${
          localStorage.getItem("call") ? "calloutcome" : "outcome"
        }`,
        body,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      toast.success("Enviado com sucesso!");
    } catch (err) {
      toast.error(err.response?.data?.errmsg || "Erro ao enviar");
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      {renderDropdowns(tabs)}
      <div className="tabDivision">
        <button className="tabSend" type="submit">
          Enviar
        </button>
        <button
          className="tabClean"
          type="reset"
          onClick={() => setSelectedOptions([])}
        >
          Limpar
        </button>
      </div>
    </form>
  );
}
