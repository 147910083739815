import React from "react";
import styles from "./cardDashboardHibernation.module.css";
import BarChartIcon from "@mui/icons-material/BarChart";
import UpdateIcon from "@mui/icons-material/Update";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
export default function CardDashboardHibernation({ title, data, icon }) {
  return (
    <div className={styles.cardContainer}>
      <div className={styles.title}>{title}</div>
      <div className={styles.data}>
        {icon === "barChart" ? (
          <BarChartIcon sx={{ fontSize: "32px" }} />
        ) : icon === "timer" ? (
          <UpdateIcon sx={{ fontSize: "32px" }} />
        ) : (
          <LocalLibraryIcon sx={{ fontSize: "32px" }} />
        )}

        <p className={styles.number}>{data}</p>
      </div>
    </div>
  );
}
