// import "./hibernation.css";
import newHibernationStyle from "./newHibernation.module.css";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import api from "../../services/api";

import { config, editHibernation, PURECLOUD_URL } from "../../services/utils";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

export default function RegisterHibernation({ _id }) {
  // -------------------------- States --------------------------- //
  const [hibernation, setHibernation] = useState({ body: {} });
  const [wrapupcodes, setWrapUpCodes] = useState([]);
  const [wrapEnabled, setWrapEnabled] = useState(false);
  const [priorityLastAgent, setPriorityLastAgent] = useState(false);
  const [prolongHibernation, setProlongHibernation] = useState(false);
  const [enableButton, setEnableButton] = useState(false);
  const hibernationOption = config.application.features.HibernationWaitRegister;
  const prolongHibernationOption =
    config.application.features.prolongHibernation;

  const wraping = config.application.modules.hibernation.wrapCode;

  const prolongOptions = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30,
  ];
  // ------------------------------------------------------------ //

  useEffect(() => {
    getWPCode();
    callAPI();
    timeCalculation();
  }, []);

  useEffect(() => {
    if (!prolongHibernation) {
      let _hibernation = { ...hibernation };
      _hibernation.body.postponeHibernationTimer = [];
      setHibernation(_hibernation);
    }
  }, [prolongHibernation]);

  // ------------------------ Functions ------------------------- //

  async function callAPI() {
    if (!hibernation.body.queueName) {
      if (_id) {
        const editValues = await editHibernation(_id);
        setHibernation({ body: editValues.msg });

        if (
          editValues.msg.wrapUpCodeId === null ||
          !editValues.msg.wrapUpCodeId.length
        ) {
          setWrapEnabled(false);
        }
        if (editValues.msg.wrapUpCodeId !== null) {
          setWrapEnabled(true);
        }
        if (editValues.msg?.postponeHibernationTimer?.length) {
          setProlongHibernation(true);
        }

        setPriorityLastAgent(editValues.msg.sameAgent);
      }
    }
  }

  async function getWPCode() {
    if (wrapupcodes.length === 0) {
      return await api
        .get(`${PURECLOUD_URL}/routing/wrapupcodes?pageSize=1000`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("pcToken")}`,
          },
        })
        .then((res) => {
          if (res.data.entities.length == 0) {
            toast.warn("Usuario não possui Wrap-up cadastrados");
          } else {
            setWrapUpCodes(res.data.entities);
          }
        })
        .catch((error) => {
          if (
            error?.response?.data?.code == "authentication.required" ||
            error?.response?.data?.code == "bad.credentials"
          ) {
            toast.warn("Sessão do genesys expirada");
            localStorage.removeItem("pcToken");
            reload(3000);
          } else {
            toast.error("Wrap-up Codes Não Encontrados");
          }
        });
    }
  }

  function wrapCleanUp() {
    setWrapEnabled(!wrapEnabled);
    let cleanup = hibernation;
    cleanup.body.wrapUpCodeId = "";
    // cleanup.body.wrapUpCodeId = undefined;
    setHibernation(cleanup);
  }

  function setProlong() {
    if (!hibernation.body.postponeHibernationTimer?.length) {
      addNewFieldProlong();
    }
    setProlongHibernation(!prolongHibernation);
  }

  function handleProlongHibernation(index, value) {
    let _hibernation = { ...hibernation };
    _hibernation.body.postponeHibernationTimer[index] = value;
    setHibernation(_hibernation);
  }

  function wrapSelect() {
    return wrapupcodes.map((wraps, idx) => (
      <option className="centerDrop" value={wraps.id} key={idx}>
        {wraps.name}
      </option>
    ));
  }

  function reload(timer) {
    setTimeout(
      function () {
        window.location.reload();
      },
      timer ? timer : 2000
    );
  }

  function timeCalculation(selected) {
    const selectOptions = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];
    let selector = selected;

    if (selector) {
      for (let i = 60; i < 721; i = i + 30) {
        selectOptions.push(i);
      }
    }

    if (selector === false) {
      for (let i = 60; i < 1441; i = i + 60) {
        selectOptions.push(i);
      }
    }

    return selectOptions.map((time, idx) => (
      <option className="centerDrop" value={time} key={idx}>
        {toHour(time)}
      </option>
    ));
  }

  function activePrioritize() {
    setPriorityLastAgent(!priorityLastAgent);

    handleChange("sameAgent", !priorityLastAgent);
    let cleanup = hibernation;
    cleanup.body.tentativas = null;
    cleanup.body.intervaloEntreTentativas = null;
    cleanup.body.returnQueueName = null;
    setHibernation(cleanup);
  }

  function tryOptions() {
    let options = [1, 2, 3];

    return options.map((op, index) => (
      <option className="centerDrop" value={op} key={index}>
        {op}
      </option>
    ));
  }
  function prepareString(op) {
    if (op <= 9) {
      return `00:0${op}`;
    } else {
      return `00:${op}`;
    }
  }
  function intervalTime() {
    let options = [];
    for (let i = 1; i <= 5; i++) {
      options.push(i);
    }
    return options.map((op, index) => (
      <option className="centerDrop" value={op} key={index}>
        {prepareString(op)}
      </option>
    ));
  }

  function addNewFieldProlong() {
    let _hibernation = { ...hibernation };
    if (_hibernation.body.postponeHibernationTimer?.length) {
      if (_hibernation.body.postponeHibernationTimer.length <= 3) {
        _hibernation.body.postponeHibernationTimer.push(" ");
      }
    } else {
      _hibernation.body.postponeHibernationTimer = [" "];
    }

    setHibernation(_hibernation);
  }

  function deleteHibernationProlong(index) {
    let _hibernation = { ...hibernation };
    _hibernation.body.postponeHibernationTimer.splice(index, 1);
    setHibernation(_hibernation);
    if (index == 0) {
      setProlong();
    }
  }

  function toHour(time) {
    if (time >= 60 && time % 60 != 0) {
      return ("0" + (time - 30) / 60).slice(-2) + ":30";
    }
    if (time >= 60 && time % 60 == 0) {
      return ("0" + time / 60).slice(-2) + ":00";
    }
    return "00:" + ("0" + time).slice(-2);
  }

  const handleChange = (type, value) => {
    let _hibernation = { ...hibernation };
    _hibernation.body[type] = value;
    setHibernation({ ..._hibernation });
  };

  const handleSubmit = async (event) => {
    //if edit no submit
    event.persist();
    event.preventDefault();
    setEnableButton(true);
    if (!_id) {
      await api
        .post("api/espresso/hibernation/create", hibernation.body, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          toast.success("Cadastrado, atualizando lista");
          reload();
        })
        .catch((err) => {
          setEnableButton(false);
          toast.error("Erro #005: Erro Fatal! Acionar o suporte");
        });
    } else {
      api
        .post(`api/espresso/hibernation/edit/${_id}`, hibernation.body, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          toast.success("Editado, atualizando lista");
          reload();
        })
        .catch((err) =>
          toast.error("Erro #005: Erro Fatal! Acionar o suporte")
        );
    }
  };

  const [startMessageLines, setStartMessageLines] = useState(1);
  const [endMessageLines, setEndMessageLines] = useState(1);

  useEffect(() => {
    if (hibernation?.body?.hibernationStartMessage?.length > 84) {
      setStartMessageLines(3);
    } else {
      if (hibernation?.body?.hibernationStartMessage?.length > 0) {
        setStartMessageLines(2);
      } else {
        setStartMessageLines(2);
      }
    }
  }, [hibernation.body.hibernationStartMessage]);

  useEffect(() => {
    if (hibernation?.body?.hibernationEndingMessage?.length > 84) {
      setEndMessageLines(3);
    } else {
      if (hibernation?.body?.hibernationEndingMessage > 0) {
        setEndMessageLines(2);
      } else {
        setEndMessageLines(1);
      }
    }
  }, [hibernation.body.hibernationEndingMessage]);

  return (
    <div className={newHibernationStyle.containerHibernation}>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="d-flex flex-column mb-3 pe-4">
          <div className="containerLabel mb-2">
            <label className="defaultLabel">Nome Da Fila</label>
          </div>
          <input
            className="input-bootstrap"
            type="text"
            required
            onChange={(e) => handleChange("queueName", e.target.value)}
            value={hibernation.body.queueName}
            placeholder="Ex: Fila compras"
          />
        </div>

        <div className="d-flex flex-column mb-3">
          <div className="containerLabel mb-2">
            <label className="defaultLabel mb-1">
              Mensagem de Estado de Hibernação
            </label>
          </div>
          <textarea
            id="messageHibernation"
            placeholder="Ex: Faz um tempo que não nos responde, quando quiser ser atendido digite oi"
            required
            className="text-area-bootstrap"
            rows={startMessageLines}
            onChange={(e) =>
              handleChange("hibernationStartMessage", e.target.value)
            }
            value={hibernation.body.hibernationStartMessage}
          />
        </div>
        <div className="d-flex flex-column mb-3">
          <div className="containerLabel mb-2">
            <label className="defaultLabel">Tempo Para Hibernar</label>
          </div>
          <select
            className="select-bootstrap"
            required
            value={hibernation.body.startHibernationTime || ""}
            onChange={(e) =>
              handleChange("startHibernationTime", e.target.value)
            }
          >
            <option value={""} hidden>
              {"Selecione uma opção"}
            </option>
            {timeCalculation(false)}
          </select>
        </div>
        <div className="d-flex flex-column mb-3">
          <div className="containerLabel mb-2">
            <label className="defaultLabel">
              Mensagem de Finalização da Hibernação
            </label>
          </div>
          <textarea
            placeholder="Ex: Estamos te enchaminhando para um atendente"
            className="text-area-bootstrap"
            required
            rows={endMessageLines}
            onChange={(e) =>
              handleChange("hibernationEndingMessage", e.target.value)
            }
            value={hibernation.body.hibernationEndingMessage}
          />
        </div>
        <div className="d-flex flex-column mb-3">
          <div className="containerLabel mb-2">
            <label className="defaultLabel">Tempo de Hibernação</label>
          </div>
          <select
            className="select-bootstrap"
            required
            value={hibernation.body.totalHibernationTime || ""}
            onChange={(e) =>
              handleChange("totalHibernationTime", e.target.value)
            }
          >
            <option value={""} hidden>
              {"Selecione uma opção"}
            </option>
            {timeCalculation(false)}
          </select>
        </div>
        <div className="d-flex flex-column pe-4 mb-3">
          <div className="containerLabel mb-2">
            <label className="defaultLabel">Fila de Hibernação</label>
          </div>
          <input
            placeholder="Ex: FIla ABC"
            className="input-bootstrap"
            type="text"
            value={hibernation.body.hibernationQueue || ""}
            onChange={(e) => handleChange("hibernationQueue", e.target.value)}
          />
        </div>
        {wraping ? (
          <div className={newHibernationStyle.toggle}>
            <label className={newHibernationStyle.lastAgent}>
              Wrap-up Code?
            </label>

            <span
              className={newHibernationStyle.toggleWrap}
              onClick={() => wrapCleanUp()}
            >
              {" "}
              {!wrapEnabled ? (
                <ToggleOffIcon style={{ color: "#e74c3c" }} />
              ) : (
                <ToggleOnIcon style={{ color: "#007bff" }} />
              )}
            </span>
          </div>
        ) : null}
        {wraping && wrapEnabled ? (
          <div className="d-flex">
            <select
              className="select-bootstrap"
              required
              disabled={!wrapEnabled}
              value={hibernation.body.wrapUpCodeId}
              onChange={(e) => handleChange("wrapUpCodeId", e.target.value)}
            >
              <option value="" hidden>
                Selecione uma Opção
              </option>
              {wrapSelect()}
            </select>
          </div>
        ) : null}
        {hibernationOption ? (
          <>
            <div className={`${newHibernationStyle.toggle} mb-3`}>
              <label className={newHibernationStyle.lastAgent}>
                Priorizar o ultimo agente?
              </label>
              <span
                className={`${newHibernationStyle.toggleWrap} ${newHibernationStyle.agentPriorize}`}
                onClick={() => activePrioritize()}
              >
                {" "}
                {!priorityLastAgent ? (
                  <ToggleOffIcon style={{ color: "#e74c3c" }} />
                ) : (
                  <ToggleOnIcon style={{ color: "#007bff" }} />
                )}
              </span>
            </div>
            {priorityLastAgent ? (
              <div>
                <div className="d-flex flex-column pe-4 mb-3">
                  <div className="containerLabel mb-2">
                    <label className="defaultLabel">Fila de Retorno</label>
                  </div>
                  <input
                    placeholder="Ex: FIla ABC"
                    className="input-bootstrap"
                    type="text"
                    value={hibernation.body.returnQueueName || ""}
                    onChange={(e) =>
                      handleChange("returnQueueName", e.target.value)
                    }
                  />
                </div>
                <div className="d-flex flex-column mb-3">
                  <div className="containerLabel mb-2">
                    <label className="defaultLabel">
                      Quantidade de tentativas
                    </label>
                  </div>
                  <select
                    value={hibernation.body.tentativas || ""}
                    onChange={(e) => handleChange("tentativas", e.target.value)}
                    className="select-bootstrap"
                  >
                    <option value="">Selecione uma Opção</option>
                    {tryOptions()}
                  </select>
                </div>
                <div className="d-flex flex-column mb-3">
                  <div className="containerLabel mb-3">
                    <label className="defaultLabel">
                      Tempo entre as tentativas(minutos)
                    </label>
                  </div>
                  <select
                    value={hibernation.body.intervaloEntreTentativas || ""}
                    onChange={(e) =>
                      handleChange("intervaloEntreTentativas", e.target.value)
                    }
                    className="select-bootstrap"
                  >
                    <option value="">Selecione uma Opção</option>
                    {intervalTime()}
                  </select>
                </div>
              </div>
            ) : null}
          </>
        ) : null}

        {prolongHibernationOption ? (
          <>
            <div className={`${newHibernationStyle.toggle} mb-3`}>
              <label className={newHibernationStyle.lastAgent}>
                Permitir prolongamento da hibernação?
              </label>
              <span
                className={`${newHibernationStyle.toggleWrap} ${newHibernationStyle.agentPriorize}`}
                onClick={() => setProlong()}
              >
                {" "}
                {!prolongHibernation ? (
                  <ToggleOffIcon style={{ color: "#e74c3c" }} />
                ) : (
                  <ToggleOnIcon style={{ color: "#007bff" }} />
                )}
              </span>
            </div>
            {prolongHibernation ? (
              <div className="mb-4">
                {hibernation.body.postponeHibernationTimer?.length
                  ? hibernation.body.postponeHibernationTimer.map(
                      (item, index) => (
                        <div className="mb-3" key={index}>
                          <div className="d-flex align-items-center justify-content-between">
                            <div>
                              <label className="defaultLabel">
                                Selecione o tempo:
                              </label>
                            </div>
                            <button
                              type="button"
                              onClick={() => deleteHibernationProlong(index)}
                              className={newHibernationStyle.btnExclude}
                            >
                              <DeleteIcon style={{ color: "red" }} />
                            </button>
                          </div>
                          <select
                            name="hibernationProlong"
                            id="hp"
                            className="select-bootstrap"
                            onChange={(e) =>
                              handleProlongHibernation(index, e.target.value)
                            }
                            value={
                              hibernation.body.postponeHibernationTimer[index]
                            }
                          >
                            <option value={""}>Selecione</option>
                            {prolongOptions.map((op) => (
                              <option value={op} key={op}>
                                {prepareString(op)}
                              </option>
                            ))}
                          </select>
                        </div>
                      )
                    )
                  : null}
                {hibernation.body?.postponeHibernationTimer?.length < 3 ? (
                  <div className="d-flex justify-content-center mt-4">
                    <button
                      onClick={addNewFieldProlong}
                      type="button"
                      className={`${newHibernationStyle.btnAdd} btn-primary`}
                    >
                      <AddIcon style={{ color: "#fff" }} />
                    </button>
                  </div>
                ) : null}
              </div>
            ) : null}
          </>
        ) : null}

        <div className="d-flex justify-content-center py-3">
          <button
            disabled={enableButton}
            className="btn btn-primary containerBtn-4"
          >
            Salvar
          </button>
        </div>
      </form>
    </div>
    // <div className="hiberCall">
    //   <form onSubmit={(e) => handleSubmit(e)}>
    //     <div className="hibernationField">
    //       <div className="hibernationAlign">
    //         <label className="labeling">Nome Da Fila</label>
    //       </div>
    //       <input
    //         type="text"
    //         required
    //         onChange={(e) => handleChange("queueName", e.target.value)}
    //         value={hibernation.body.queueName}
    //       />
    //     </div>

    //     <div className="hibernationField">
    //       <div className="hibernationAlign">
    //         <label className="labeling">Mensagem de Estado de Hibernação</label>
    //       </div>
    //       {/* <input
    //         type="text"
    //         required
    //         onChange={(e) =>
    //           handleChange("hibernationStartMessage", e.target.value)
    //         }
    //         value={hibernation.body.hibernationStartMessage}
    //       /> */}
    //       <textarea className="text-area" rows={20}/>
    //     </div>

    //     <div className="boxContent">
    //       <div className="hibernationAlign">
    //         <label className="labeling">Tempo Para Hibernar</label>
    //       </div>
    //       <select
    //         className="selectTimer"
    //         required
    //         onChange={(e) =>
    //           handleChange("startHibernationTime", e.target.value)
    //         }
    //         value={hibernation.body.startHibernationTime}
    //       >
    //         <option value={null} hidden>
    //           Selecione uma opção
    //         </option>
    //         {timeCalculation(true)}
    //       </select>
    //     </div>

    //     <div className="hibernationField">
    //       <div className="hibernationAlign">
    //         <label className="labeling">
    //           Mensagem de Finalização da Hibernação
    //         </label>
    //       </div>
    //       <input
    //         type="text"
    //         required
    //         onChange={(e) =>
    //           handleChange("hibernationEndingMessage", e.target.value)
    //         }
    //         value={hibernation.body.hibernationEndingMessage}
    //       />
    //     </div>

    //     <div className="boxContent">
    //       <div className="hibernationAlign">
    //         <label className="labeling">Tempo de Hibernação</label>
    //       </div>

    //       <select
    //         className="selectTimer"
    //         required
    //         value={hibernation.body.totalHibernationTime}
    //         onChange={(e) =>
    //           handleChange("totalHibernationTime", e.target.value)
    //         }
    //       >
    //         <option value={null} hidden>
    //           {"Selecione uma opção"}
    //         </option>
    //         {timeCalculation(false)}
    //       </select>
    //     </div>

    //     {wraping
    //       ? <div className="wraping">
    //         <div className="hibernationAlign">
    //           <label className="labeling">Wrap-up Code</label>
    //         </div>
    //         <div className="enabling">
    //             <select
    //               className="selectWrap"
    //               required
    //               disabled={wrapEnabled}
    //               value={hibernation.body.wrapUpCodeId}
    //               onChange={(e) => handleChange("wrapUpCodeId", e.target.value)}
    //             >
    //               <option value="" hidden>
    //                 Selecione uma Opção
    //               </option>
    //               {wrapSelect()}
    //             </select>
    //           <span className="toggleWrap" onClick={() => wrapCleanUp()}> {wrapEnabled ? <ToggleOffIcon style={{ color: "#e74c3c" }} /> : <ToggleOnIcon style={{ color: "#1abc9c" }} />}</span>
    //         </div>
    //       </div>
    //       : null}

    //     <div className="spacingButton">
    //       <button className="saveBtn" type="submit" onClick={reload}>
    //         Salvar
    //       </button>
    //     </div>
    //   </form>
    // </div>
  );
}
