import Chart from "react-apexcharts";

export default function LineChartSAC({ data, key }) {
  const hours = Array.from(
    { length: 24 },
    (_, i) => `${i.toString().padStart(2, "0")}:00`
  );
  const seriesData = Object.values(data);
  const categories = Object.keys(data);
  console.log(seriesData);
  const chartData = {
    series: [
      {
        name: "Hibernações",
        data: seriesData,
      },
    ],

    options: {
      chart: {
        type: "line",
        toolbar: {
          show: false,
        },
      },
      markers: {
        size: 5,
        hover: {
          size: 8,
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#007BFF"],
      legend: {
        show: true,
      },
      xaxis: {
        categories: categories,
      },
    },
  };

  return (
    <Chart
      key={key}
      options={chartData.options}
      series={chartData.series}
      type="line"
      height="270px"
    />
  );
}
