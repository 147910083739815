import style from "./dadosChat.module.css";
import { useEffect, useState } from "react";
import api from "../../services/api";

export default function DadosChat({ match }) {
  const telefone = match.params.telefone;
  const origin = match.params.origin;

  const [metaData, setMetaData] = useState({});
  function downloadFunction(base64Data, fileName = "arquivo") {
    const url = `${base64Data}`;

    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  }

  async function callApi() {
    const response = await api.post(
      "/api/database/getArthursBookByOriginPhone",
      {
        origin: origin,
        phone: telefone,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    if (response.data) {
      const meta = response.data.Monmouth.specificData.mailData;
      setMetaData(meta);
    }
  }

  useEffect(() => {
    callApi();
  }, []);

  return (
    <div className={style.container}>
      <div className={style.title}>Credenciados</div>
      <div className="mb-3">
        <label>Nome:</label>
        <input
          disabled
          type="text"
          className="input-bootstrap"
          value={metaData?.nome}
        />
      </div>
      <div className="mb-3">
        <label>CPF:</label>
        <input
          disabled
          type="text"
          className="input-bootstrap"
          value={metaData?.cpf}
        />
      </div>
      <div className="mb-3">
        <label>Assunto:</label>
        <input
          disabled
          type="text"
          className="input-bootstrap"
          value={metaData?.assunto}
        />
      </div>
      <div>
        {metaData?.documento?.length > 0 && (
          <>
            <label>Arquivos</label>
            <div className={style.archivesContainer}>
              <div className="d-flex align-items-center flex-wrap">
                {metaData.documento.map((documento, index) => (
                  <div key={index}>
                    <button
                      className={style.documentButton}
                      onClick={() =>
                        downloadFunction(documento.base64, documento.name)
                      }
                    >
                      {documento.name}
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
