import React, { useEffect, useState } from "react";
import CircularLoading from "../../../components/loadModules/circularLoading";
import { toast } from "react-toastify";
import api from "../../../services/api";
import style from "../dashboardHibernation.module.css";
import LineChartSAC from "../../../components/charts/dashboardHibernation/lineChartSAC";
export default function DashboardSACWhatsappHours() {
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState("");
  const [enterData, setEnterData] = useState([]);
  const [returnQueueData, setReturnQueueData] = useState([]);
  const [disconnectedData, setDisconnectedData] = useState([]);
  const [returnedAgent, setReturnedAgent] = useState([]);

  useEffect(() => {
    if (params.length) {
      callApi();
    }
  }, [params]);

  const processData = (data) => {
    return data.reduce((acc, item) => {
      const hour = `${item.Hora.toString().padStart(2, "0")}:00`;
      acc[hour] = (acc[hour] || 0) + item.Hibernacoes;
      return acc;
    }, {});
  };

  const processDataByStatus = (data, statusFilter = null) => {
    return data.reduce((acc, item) => {
      if (statusFilter && item.Status !== statusFilter) {
        return acc;
      }

      const hour = `${item.Hora.toString().padStart(2, "0")}:00`;
      if (!acc[item.Status]) {
        acc[item.Status] = {};
      }
      acc[item.Status][hour] = (acc[item.Status][hour] || 0) + item.Hibernacoes;
      return acc;
    }, {});
  };

  async function callApi() {
    setLoading(true);
    await api
      .get(`/api/database/report/dashDailyStatus?dataref=${params}T00:00:00 `, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.length) {
          let _enterData = processData(res.data);
          let _returnedQueue = processDataByStatus(res.data, "RETURNED_QUEUE");
          let _disconnected = processDataByStatus(res.data, "DISCONNECTED");
          let _returnedAgent = processDataByStatus(res.data, "RETURNED_AGENT");
          setReturnQueueData(_returnedQueue.RETURNED_QUEUE);
          setDisconnectedData(_disconnected.DISCONNECTED);
          setReturnedAgent(_returnedAgent.RETURNED_AGENT);
          setEnterData(_enterData);
        } else {
          setEnterData([]);
        }
      })
      .catch(() => {
        toast.error("Houve um erro ao consultar os dados!");
      })
      .finally(() => {
        setLoading(false);
      });
  }
  function handleChange(value) {
    setParams(value);
  }
  return (
    <div className="container">
      <div className="d-flex justify-content-between pt-4 pe-5 mb-4">
        <div className="titlePage">Hibernação por horário:</div>
        <div className={style.containerSelect}>
          <input
            className="input-bootstrap"
            type="date"
            value={params}
            id="searchdateto"
            onChange={(e) => handleChange(e.target.value)}
          />
        </div>
      </div>
      <div>
        {loading ? (
          <CircularLoading />
        ) : (
          <div className="pe-4">
            <div className={`d-flex align-items-center ${style.firstChild} `}>
              <div className={style.chartLineContainer}>
                <p className="text-center pb-4">Hibernação entrada por hora</p>
                <LineChartSAC key={"enterData"} data={enterData} />
              </div>

              <div className={style.chartLineContainer}>
                <p className="text-center pb-4">Hibernação retorno para fila</p>
                <LineChartSAC key={"returnQueue"} data={returnQueueData} />
              </div>
            </div>
            <div
              className={`d-flex align-items-center ${style.firstChild} pt-4 `}
            >
              <div className={style.chartLineContainer}>
                <p className="text-center pb-4">
                  Hibernação retorno para agente
                </p>
                <LineChartSAC key={"returnedAgent"} data={returnedAgent} />
              </div>
              <div className={style.chartLineContainer}>
                <p className="text-center pb-4">Hibernação desconectado</p>
                <LineChartSAC key={"disconnected"} data={disconnectedData} />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
