import React, { useState, useEffect } from "react";
import CircularLoading from "../../../components/loadModules/circularLoading";
import CardDashboardHibernation from "../../../components/charts/dashboardHibernation/cardDashboardHibernation";
import PieChartHibernation from "../../../components/charts/dashboardHibernation/pieChartHibernation";
import styles from "../dashboardHibernation.module.css";
import api from "../../../services/api";
import BarChartHibernationNewDash from "../../../components/charts/dashboardHibernation/barChartHibernationNewDash";
import { toast } from "react-toastify";
import BarChartMetrics from "../../../components/charts/dashboardHibernation/barChartMetrics";
export default function DashboardSACWhatsapp() {
  const [loading, setLoading] = useState(false);
  const [dataWeek, setDataWeek] = useState([]);
  const [dataMonth, setDataMonth] = useState([]);
  const [params, setParams] = useState([]);
  const [dataOrigin, setDataOrigin] = useState([]);
  const [dataStatus, setDataStatus] = useState([]);
  const [dataMonthHibernate, setDataMonthHibernate] = useState([]);
  const [dataCards, setDataCards] = useState([]);

  const token = localStorage.getItem("token");
  const months = {
    "01": "Jan",
    "02": "Fev",
    "03": "Mar",
    "04": "Abr",
    "05": "Mai",
    "06": "Jun",
    "07": "Jul",
    "08": "Ago",
    "09": "Set",
    10: "Out",
    11: "Nov",
    12: "Dez",
  };

  async function getHibernateAttendByWeek() {
    await api
      .get(
        `/api/database/report/dashWeek?year=${params[0]}&month=${params[1]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.length) {
          let _data = calculatePercent(res.data);
          setDataWeek(_data);
        } else {
          toast.warning("Não há dados para esse período ");
          setDataWeek([]);
        }
      })
      .catch(() => {
        toast.error("Houve um erro ao processar a requisição");
      });
  }

  async function getHibernateAttendByMonth() {
    await api
      .get(
        `/api/database/report/dashDaily?year=${params[0]}&month=${params[1]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.length) {
          let _data = formatData(res.data);
          setDataMonth(_data);
        } else {
          toast.warning("Não há dados para esse período ");
          setDataMonth([]);
        }
      })
      .catch(() => {
        toast.error("Houve um erro ao processar a requisição");
      });
  }

  async function getHibernateConsolidated() {
    await api
      .get(
        `/api/database/report/dashConsolidado?year=${params[0]}&month=${params[1]} `,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.length) {
          setDataCards(res.data);
        } else {
          toast.warning("Não há dados para esse período ");
          setDataCards([]);
        }
      })
      .catch(() => {
        toast.error("Houve um erro ao processar a requisição");
      });
  }

  async function getHibernateByOrigin() {
    await api
      .get(
        `/api/database/report/dashOrigin?year=${params[0]}&month=${params[1]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        let _data = formatDataOrigin(res.data);
        setDataOrigin(_data);
      })
      .catch(() => {
        toast.error("Houve um erro ao processar a requisição");
      });
  }
  async function getHibernateByStatus() {
    await api
      .get(
        `/api/database/report/dashStatus?year=${params[0]}&month=${params[1]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        let _data = formatDataOrigin(res.data, true);
        setDataStatus(_data);
      })
      .catch(() => {
        toast.error("Houve um erro ao processar a requisição");
      });
  }
  async function getHibernateMonth() {
    const year = params[0];
    const month = params[1];

    // Define o mês e ano para a segunda requisição
    const prevMonth = month === 1 ? 12 : month - 1;
    const prevYear = month === 1 ? year - 1 : year;

    try {
      const [currentResponse, previousResponse] = await Promise.all([
        api.get(
          `/api/database/report/dashMetricsMonth?year=${year}&month=${month}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        ),
        api.get(
          `/api/database/report/dashMetricsMonth?year=${prevYear}&month=${prevMonth}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        ),
      ]);

      setDataMonthHibernate([
        ...previousResponse.data,
        ...currentResponse.data,
      ]);
    } catch (error) {
      console.error("Erro ao buscar os dados:", error);
    }
  }

  function translateData(_data) {
    switch (_data) {
      case "DISCONNECTED":
        return "Desconectado";
      case "RETURNED_AGENT":
        return "Retorno para agente";
      case "RETURNED_QUEUE":
        return "Retorno para fila";
      default:
        return "";
    }
  }

  const handleParams = (value) => {
    const [year, month] = value.split("-");
    if (Number(year) < 2024) {
      toast.error("O ano não pode ser inferior a 2024");
      return;
    }
    setParams([year, month]);
  };

  function formatData(data) {
    data.sort((a, b) => a.Dia - b.Dia);
    data.forEach((item) => {
      item.Dia = `${item.Dia} ${months[params[1]]}`;
    });
    data = calculatePercent(data);
    return data;
  }

  function formatDataOrigin(data, status) {
    let _data = [];
    if (!status) {
      for (let i in data) {
        let _prov = { name: "", data: "" };
        _prov.name = data[i].Origem;
        _prov.data = data[i].Hibernacoes;
        _data.push(_prov);
      }
    } else {
      for (let i in data) {
        let _prov = { name: "", data: "" };
        _prov.name = translateData(data[i].Status);
        _prov.data = data[i].Hibernacoes;
        _data.push(_prov);
      }
    }

    return _data;
  }
  function calculatePercent(data) {
    data.sort((a, b) => a.Semana - b.Semana);
    data.forEach((item) => {
      if (item.Atendidas != 0) {
        const percent = (item.Hibernacoes / item.Atendidas) * 100;
        item.percent = percent.toFixed(2);
      }
    });
    return data;
  }

  useEffect(() => {
    if (params.length) {
      setLoading(true);
      Promise.all([
        getHibernateAttendByWeek(),
        getHibernateAttendByMonth(),
        getHibernateByOrigin(),
        getHibernateByStatus(),
        getHibernateConsolidated(),
        getHibernateMonth(),
      ]).finally(() => setLoading(false));
    }
  }, [params]);

  return (
    <div className="container">
      <div className="d-flex align-items-center justify-content-between pt-4">
        <div className="titlePage">Resultados SAC Whatsapp:</div>
        <div className={styles.containerSelectHeader}>
          <input
            onChange={(e) => handleParams(e.target.value)}
            type="month"
            className="input-bootstrap me-4"
          />
        </div>
      </div>
      <div style={{ position: "relative" }}>
        {loading ? (
          <CircularLoading />
        ) : (
          <>
            <div className="d-flex align-items-center justify-content-center">
              <CardDashboardHibernation
                title={"Total de Hibernação"}
                data={dataCards[0]?.TotalHibernation}
                icon={"barChart"}
              />
              <CardDashboardHibernation
                title={"% Retorno"}
                data={
                  dataCards[0]?.ReturnPercentage
                    ? `${dataCards[0]?.ReturnPercentage}%`
                    : ""
                }
                icon={"timer"}
              />
              <CardDashboardHibernation
                title={"Clientes Únicos"}
                data={dataCards[0]?.UniqueCustomers}
                icon={""}
              />
            </div>
            <div className="d-flex align-items-center justify-content-center pt-4">
              <div className={styles.pieContainerWhats}>
                <p className="pb-4 text-center">
                  {" "}
                  Tratamento vs Hibernação mês
                </p>
                <BarChartMetrics data={dataMonthHibernate} />
              </div>
              <div className={styles.pieContainerWhats}>
                <p className="pb-4 text-center">Definição por tipo e TMH</p>
                <PieChartHibernation data={dataStatus} />
              </div>
              <div className={styles.pieContainerWhats}>
                <p className="pb-4  text-center">Hibernação por bandeira</p>
                <PieChartHibernation data={dataOrigin} />
              </div>
            </div>

            <div className="pt-4 d-flex justify-content-center align-items-center ">
              <div
                className={`${styles.pieContainerWhats} ${styles.marginNegative}`}
              >
                <p className="text-center pb-4">
                  Tratamento VS Hibernação por semana
                </p>
                <BarChartHibernationNewDash
                  key="week"
                  data={dataWeek}
                  type={"week"}
                />
              </div>
              <div className={styles.dayContainer}>
                <p className="text-center pb-4">
                  {" "}
                  Tratamento VS Hibernação por dia
                </p>
                <BarChartHibernationNewDash
                  key="month"
                  data={dataMonth}
                  type={"month"}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
