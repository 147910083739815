import React, { useEffect } from "react";
import Chart from "react-apexcharts";

// Enum para os meses por extenso
const MesesEnum = {
  1: "Janeiro",
  2: "Fevereiro",
  3: "Março",
  4: "Abril",
  5: "Maio",
  6: "Junho",
  7: "Julho",
  8: "Agosto",
  9: "Setembro",
  10: "Outubro",
  11: "Novembro",
  12: "Dezembro",
};

export default function BarChartMetrics({ data, showLabels, showTotal }) {
  const chartData = {
    series: [
      {
        name: "Atendidas",
        data: data.map((item) => item.Atendidas),
      },
      {
        name: "Hibernadas",
        data: data.map((item) => item.Hibernacoes),
      },
    ],
    options: {
      plotOptions: {
        bar: {
          barHeight: "100%",
          distributed: false,
          horizontal: false,
          dataLabels: {
            position: "outside",
          },
        },
      },

      dataLabels: {
        enabled: showLabels,
        style: {
          colors: ["#383737"],
        },
      },
      colors: ["#007BFF", "#26979E", "#456099"],
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },

      legend: {
        show: true,
      },

      xaxis: {
        categories: data.map((item) => MesesEnum[item.Mes]),
      },
      yaxis: [
        {
          seriesName: "Hibernadas",
          axisTicks: { show: true },
          axisBorder: { show: true },
          labels: { style: { colors: "#008FFB" } },
          title: {
            text: "Hibernadas / Atendidas",
            style: { color: "#008FFB" },
          },
        },
        {
          seriesName: "Atendidas",
          axisTicks: { show: true },
          axisBorder: { show: false },
          labels: { show: false },
        },
      ],

      chart: {
        toolbar: {
          tools: { download: false },
        },
      },
    },
  };

  return (
    <Chart
      options={chartData.options}
      series={chartData.series}
      type="bar"
      height={`280px`}
    />
  );
}
