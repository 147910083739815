import React from "react";
import {
  DataGrid,
  ptBR,
  GridToolbarExport,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import "./table.css";
import Tooltip from "@mui/material/Tooltip";
import { withStyles } from "@material-ui/core";

const StyledDataGrid = withStyles({
  root: {
    "& .MuiDataGrid-renderingZone": {
      maxHeight: "none !important",
    },
    "& .MuiDataGrid-panelWrapper": {
      width: "100px",
    },
    "& .MuiDataGrid-cell": {
      lineHeight: "unset !important",
      maxHeight: "none !important",
      whiteSpace: "normal",
      fontSize: "calc(6px + 0.4vw)",
    },
    "& .MuiDataGrid-row": {
      maxHeight: "none !important",
    },
    "& .MuiDataGrid-toolbarContainer": {
      minHeight: "40px",
      maxHeight: "40px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    "& .MuiDataGrid-toolbarContainer .MuiButton-root": {
      margin: "0",
      padding: "16px",
      zIndex: "1",
      position: "relative",
      minWidth: "180px",
      textAlign: "start",
    },
  },
})(DataGrid);

const CustomToolbar = ({ setFilterButtonEl }) => (
  <GridToolbarContainer>
    <GridToolbarFilterButton ref={setFilterButtonEl} />
    <GridToolbarExport
      csvOptions={{ delimiter: ";" }}
      printOptions={{ disableToolbarButton: true }}
    />
  </GridToolbarContainer>
);

const columns = [
  {
    field: "ID",
    headerName: "ID",
    flex: 1,
  },
  {
    field: "ID Conversa",
    headerName: "ID Conversa",
    flex: 1,
  },
  {
    field: "Data e Hora",
    headerName: "Data",
    type: "dateTime",
    headerAlign: "center",
    align: "center",
    flex: 1,
    renderCell: (params) => (
      <div>
        {params.value === null ? (
          "-"
        ) : (
          <div>
            <Tooltip title={new Date(params.value).toLocaleString()}>
              <span className="table-cell-trucate">
                {new Date(params.value).toLocaleString()}
              </span>
            </Tooltip>
          </div>
        )}
      </div>
    ),
  },
  {
    field: "Data de contato",
    headerName: "Data do contato",
    type: "dateTime",
    headerAlign: "center",
    align: "center",
    flex: 1,
    renderCell: (params) => (
      <div>
        {params.value === null ? (
          "-"
        ) : (
          <div>
            {params.value ? (
              <>
                <Tooltip title={new Date(params.value).toLocaleString()}>
                  <span className="table-cell-trucate">
                    {new Date(params.value).toLocaleString()}
                  </span>
                </Tooltip>
              </>
            ) : (
              "-"
            )}
          </div>
        )}
      </div>
    ),
  },

  { field: "Cliente", headerName: "Cliente", flex: 1 },
  //  { field: "NomeClient", headerName: "Nome do Cliente", flex: 1 },

  {
    field: "Numero do Protocolo",
    headerName: "ID interação",
    renderCell: (params) => {
      if (params.value === null) {
        return "System.Empty";
      }
      return params.value;
    },
    flex: 1,
    headerAlign: "center",
    align: "left",
  },

  { field: "Matricula operador", headerName: "Matricula operador", flex: 1 },

  {
    field: "Motivo",
    headerName: "Motivo",
    flex: 1,

    renderCell: (params) => (
      <div>{params.value ? <div>{params.value}</div> : <div>-</div>}</div>
    ),
  },
  {
    field: "Evento",
    headerName: "Evento",
    flex: 1,
  },
  {
    field: "ID Fila",
    headerName: "ID Fila",
    flex: 1,

    renderCell: (params) => (
      <div>{params.value ? <div>{params.value}</div> : <div>-</div>}</div>
    ),
  },
];

const generateUniqueIds = (data) => {
  return data.map((item, index) => ({
    ...item,
    id: item.id || `${item.Cliente}-${index}`, // Use `Cliente` + `index` como ID único
  }));
};

export default function ReactiveTable({ data }) {
  const processedData = generateUniqueIds(data);
  return (
    <>
      <div style={{ height: "110%", width: "100%" }}>
        <StyledDataGrid
          disableSelectionOnClick
          components={{
            Toolbar: CustomToolbar,
          }}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          rows={processedData}
          getRowId={(row) => {
            return row?.id;
          }}
          // @ts-ignore
          columns={columns}
        />
      </div>
    </>
  );
}
