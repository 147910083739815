import GenesysHistory from "./genesysHistory";
import DadosChat from "./dadosChat";
import React, { useState } from "react";
import { Tabs, Tab, Box, Typography } from "@mui/material";

function TabPanel({
  children,
  value,
  index,
}: {
  children: React.ReactNode,
  value: number,
  index: number,
}) {
  return (
    <div hidden={value !== index}>
      {value === index && (
        <Box p={2}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function ContainerHistory({ match }) {
  const [value, setValue] = useState(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
      <Tabs className="ps-4" value={value} onChange={handleChange}>
        <Tab label="Histórico" />
        <Tab label="Dados do fluxo" />
      </Tabs>

      <TabPanel value={value} index={0}>
        <GenesysHistory match={match} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <DadosChat match={match} />
      </TabPanel>
    </Box>
  );
}
