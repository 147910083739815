import React, { useEffect } from "react";
import Chart from "react-apexcharts";

export default function BarChartHibernation({
  data,
  type,
  showLabels,
  showTotal,
}) {
  const chartData = {
    series: [
      {
        name: "Atendidas",
        data: [],
      },
      {
        name: "Hibernadas",
        data: [],
      },
    ],
    options: {
      plotOptions: {
        bar: {
          barHeight: "100%",
          distributed: false,
          horizontal: false,
          dataLabels: {
            position: "outside",
          },
        },
      },

      dataLabels: {
        enabled: showLabels,
        style: {
          colors: ["#383737"],
        },
      },
      colors: ["#007BFF", "#26979E", "#456099"],
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },

      legend: {
        show: true,
      },

      xaxis: {
        categories: [],
      },
      yaxis: [
        {
          seriesName: "Hibernadas",
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
          },
          labels: {
            style: {
              colors: "#008FFB",
            },
          },
          title: {
            text: "Hibernadas / Atendidas",
            style: {
              color: "#008FFB",
            },
          },
        },
        {
          seriesName: "Atendidas",
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: false,
          },
          labels: {
            show: false,
          },
        },
      ],

      chart: {
        toolbar: {
          tools: {
            download: false,
          },
        },
      },
    },
  };

  if (type == "week") {
    data.forEach((week, index) => {
      const weekName = `Semana ${index + 1}`;
      chartData.options.xaxis.categories.push(weekName);

      chartData.series[0].data.push(parseInt(week.Atendidas));
      chartData.series[1].data.push(parseInt(week.Hibernacoes));
    });

    const totalAtendidas = data.reduce(
      (acc, week) => acc + parseInt(week.Atendidas),
      0
    );
    const totalHibernadas = data.reduce(
      (acc, week) => acc + parseInt(week.Hibernacoes),
      0
    );

    if (showTotal) {
      const totalPercent = ((totalHibernadas / totalAtendidas) * 100).toFixed(
        2
      );

      chartData.options.xaxis.categories.push("Total Geral");

      chartData.series[0].data.push(totalAtendidas);
      chartData.series[1].data.push(totalHibernadas);
    }
  }
  if (type == "month") {
    data.forEach((day, index) => {
      const dayName = day.Dia;
      chartData.options.xaxis.categories.push(dayName);

      chartData.series[0].data.push(parseInt(day.Atendidas));
      chartData.series[1].data.push(parseInt(day.Hibernacoes));
    });
  }

  return (
    <Chart
      options={chartData.options}
      series={chartData.series}
      type="bar"
      height={`280px`}
    />
  );
}
