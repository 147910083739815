import React from "react";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@mui/icons-material/Close";
//https://vvjdev.lwespresso.com/api/database/conversation/CasasBahia/
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "20px",
  },
  icon: {
    color: " rgb(238, 0, 0)",
    fontSize: "72px",
  },
  text: {
    fontSize: "18px",
    textAlign: "center",
    fontFamily: "Open sans",
    margin: "0px",
  },
}));

export default function ClientDataModal({ handleModal, open, clientData }) {
  const classes = useStyles();
  return (
    <Modal
      open={open}
      onClose={handleModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={classes.paper}>
        <div className="d-flex justify-content-end">
          <button onClick={() => handleModal()} className="btn">
            <CloseIcon />
          </button>
        </div>
        <div className="text-center mt-1 mb-3">
          <h2 className={classes.text}>Informações do cliente</h2>
        </div>
        <div>
          <div className="mb-4">
            <label htmlFor="">Numero protocolo</label>
            <input
              className="input-bootstrap"
              type="text"
              name=""
              disabled
              id=""
              value={clientData?.metadata?.Protocol}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="">Nome do cliente</label>
            <input
              className="input-bootstrap"
              type="text"
              name=""
              disabled
              value={clientData?.metadata?.CustomerName}
            />
          </div>
          <div>
            <label htmlFor="">Numero pedido</label>
            <input
              className="input-bootstrap"
              type="text"
              name=""
              disabled
              value={clientData?.metadata?.OrderId}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
